const KEYURI = {

        // BACKEND_URI:"http://localhost:5000/api"

        BACKEND_URI:"https://sycamore-server-eud4n.ondigitalocean.app/api"

}

export default KEYURI


